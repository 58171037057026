/* eslint-disable import/prefer-default-export */
import axios from 'axios'
// eslint-disable-next-line import/no-extraneous-dependencies
import AxiosInterceptor from '../hooks/AxiosInterceptorHook'
import {apiURLs } from './Utility'

/**
 * Custome axios instance for usermanager api
 * @param {string} bearerToken
 */
// defaults for bearer token and planetprocookie are set in loginHook
// after call to logLogin
const createFeaturesAxiosInstance = () => {
  const axiosInstance = axios.create({
    baseURL: `${apiURLs.featuresBaseUrl}`,
    headers: {
      'Content-Type': 'application/json'
    },
    withCredentials: true

  })

  const interceptor = AxiosInterceptor(axiosInstance)

  return {axiosInstance, interceptor}
}

const FeaturesAxiosInstance = createFeaturesAxiosInstance()



export default {

  getFeatures: () => {

    // eslint-disable-next-line no-underscore-dangle
    // if (window._env.FEATURES_DATA) {

    //   // This sets the mock adapter on the default instance
    //   const mock = new MockAdapter(axios);

    //   // Mock any GET request to /users
    //   // arguments for reply are (status, data, headers)
    //   mock.onGet("/").reply(200,
    //     // eslint-disable-next-line no-underscore-dangle
    //     window._env.FEATURES_DATA
    //   );

    //   return axios.get("/")
    // }


    return FeaturesAxiosInstance.axiosInstance({
      method: 'GET',
      url: "/"
    })

  },
  setJwtToken: jwtToken => {
    FeaturesAxiosInstance.axiosInstance.defaults.headers.common.Authorization = `Bearer ${jwtToken}`
  },

  setRequestInterceptor: (interceptor) => {
    FeaturesAxiosInstance.interceptor.setRequestInterceptor(interceptor)
  },
  setResponseInterceptor: (interceptor) => {
    FeaturesAxiosInstance.interceptor.setResponseInterceptor(interceptor)
  },

  ready: () => {
    return (FeaturesAxiosInstance.axiosInstance
      && FeaturesAxiosInstance.axiosInstance.defaults
      && FeaturesAxiosInstance.axiosInstance.defaults.headers
      && FeaturesAxiosInstance.axiosInstance.defaults.headers.common
      && FeaturesAxiosInstance.axiosInstance.defaults.headers.common.Authorization
      && true)
  }
}

