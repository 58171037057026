import React, {useContext, useState} from 'react'
import USBList from '@usb-shield/react-list'
import NavigationItems from '../NavigationItems/NavigationItems'
import {AuthContext} from '../../../context/AuthContext'
import {formatInterval, formatTime} from '../../../utils/Utility'

/**
 * Returns footer with next links (routable)
 */
const Footer = () => {
  const [showTime, setShowTime] = useState(false)
  const authContext = useContext(AuthContext)
  const {toggleShowTimings} = authContext
  const menuItems = [
    {
      href: '',
      alt: 'Privacy & Security',
      text: 'Privacy & Security',
      id: 'lnkSecurity',
      handleClick: () => {
        window.open('https://www.usbank.com/about-us-bank/privacy.html', 'usbankprivacysecurity')
        return false
      }
    },
    {
      href: '/ContactUs',
      alt: 'Contact Us',
      text: 'Contact Us',
      id: 'lnkContactUs'
    }
  ]

  const isAuthenticated = () => {
    // const iPlanetDirectoryProCookie = Cookies.get(getIPlanetProCookieName())
    // return iPlanetDirectoryProCookie || (authContext && authContext.isAuthenticated)
    const isAuth = authContext && authContext.isAuthenticated
    // console.log(`isAuth = '${isAuth}'`)
    return isAuth
  }
  const getTimeLeft = () => {
    return formatInterval(authContext.sessionTimeLeft)
  }
  const handleOnClick = () => {
    setShowTime((prev) => !prev)
  }
  const handleKeyDown = handleOnClick
  const handleOnDoubleClick = () => {
    toggleShowTimings()
  }

  return (
    <>
      <nav id="footerNav" style={{width: '100%', justifyContent: 'space-between', display: 'inline-flex'}}>
        <USBList variant="horizontal" type="ul" role="menu">
          <NavigationItems menuItems={menuItems} isBlueNav />
        </USBList>

        <span
          role='button'
          tabIndex={0}
          className={`timeout${(showTime ? 'show' : '')}`}
          onClick={handleOnClick}
          onDoubleClick={handleOnDoubleClick}
          onKeyDown={handleKeyDown}
        >
          {isAuthenticated() && getTimeLeft()}

        </span>
      </nav>
      {/* <div>&nbsp;</div> */}
      <div className="copyright">
        <span className="left">
          <img
            src="/svg/connectionsecured.svg"
            alt="U.S. Bank"
            title="U.S. Bank"
            className="secured"
          />
        </span>
        <span>&nbsp;Connection Secured</span>
        <span className="right">© 2020 U.S. Bank</span>
      </div>
    </>
  )
}

export default Footer
