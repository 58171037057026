import React, { useContext, useEffect, useState } from 'react'
import USBList from '@usb-shield/react-list'
import Link from 'next/link'
import { useRouter } from 'next/router'
import NavigationItems from '../NavigationItems/NavigationItems'
import { AuthContext } from '../../../context/AuthContext'
import { userType, isAdmin, isLoginEnabled, isCompanyManagerEnabled, isTMUser } from '../../../utils/Utility'

/**
 * Return top blue menu with next links (routable)
 * Renders menu items only when user is authenticated.
 * US Bank logo displays all the time.
 */
const TopBlueMenu = () => {
  const authContext = useContext(AuthContext)
  const [menuItems, setMenuItems] = useState([])
  const router = useRouter()
  const {isAuthenticated,userRoles,applicationId, features} = authContext
  const {route} = router

  /**
   * Handles page protection
   */
  useEffect(() => {
    const arr = []
    if (isLoginEnabled() && !isAuthenticated && route !== '/Login') {
      arr.push({
        href: '/Login',
        alt: 'Login',
        text: 'Login',
        id: 'lnkLogin'
      })
    } else if ( userRoles && userRoles.length > 0) {
      if (
        isLoginEnabled() &&
        (userRoles.indexOf(userType.AWSUser) > -1 ||
        userRoles.indexOf(userType.TrakkerUser) > -1 ||
        userRoles.indexOf(userType.CompanyAdmin) > -1)
      ) {
        arr.push({
          href: '/UserProfile',
          alt: 'My Profile',
          text: 'My Profile',
          id: 'lnkMyProfile'
        })
      }

      if (!isLoginEnabled() && isAdmin(userRoles) && isCompanyManagerEnabled()) {
         arr.push(
           {
             href: '/CompanyManager',
             alt: 'Company Manager',
             text: 'Company Manager',
             id: 'lnkCompanyManager'
           })
       }

      if (isAdmin(userRoles)) {
        arr.push(
          {
            href: '/UserManager',
            alt: 'User Manager',
            text: 'User Manager',
            id: 'lnkUserManager'
          }
        )
      }

      if (!isLoginEnabled() && isAdmin(userRoles)) {
        arr.push(
          {
            href: '/Reports',
            alt: 'Reports',
            text: 'Reports',
            id: 'lnkReports'
          }
        )
      }

      if (!isLoginEnabled() && isTMUser(userRoles) && features && features.TransactionMiddleware) {
        arr.push(
          {
            href: '/AtmProject',
            alt: 'Workstation Manager',
            text: 'Workstation Manager',
            id: 'lnkTM'
          })

        arr.push(
          {
            href: '/TMSyncData',
            alt: 'TM Sync Data',
            text: 'TM Sync Data',
            id: 'lnkTMsyncData'
          })

        arr.push(
          {
            href: '/TMAdHoc',
            alt: 'TM AdHoc',
            text: 'TM AdHoc',
            id: 'lnkTMAdHoc'
          })    
      }

      if(features && features.AtmVideo){
        if (!isLoginEnabled()) {
          arr.push(
            {
              href: '/AtmVideo',
              alt: 'Training Videos',
              text: 'Training Videos',
              id: 'lnkTMVideos'
            })
        }

        if (isLoginEnabled() && applicationId!==null && applicationId==='ATMAccessManagement') {
          arr.push(
            {
              href: '/AtmVideo',
              alt: 'Training Videos',
              text: 'Training Videos',
              id: 'lnkTMVideos'
            })
        }
      }       
    }
    setMenuItems(arr)
  }, [isAuthenticated, route, userRoles, applicationId, features])
  
  const usbLogoLink = (
    <USBList.Item key="usbLogo">
      <Link href="/" as="/" key="homelink">
        <a href="/">
          <img
            src="/images/usbank_logo.png"
            alt="U.S. Bank"
            title="U.S. Bank"
            itemProp="logo"
            style={{ width: '127px' }}
          />
        </a>
      </Link>
    </USBList.Item>
  )

  return (
    <nav id="topBlueNav">
      <USBList variant="horizontal" type="ul" role="menu">
        {usbLogoLink}
        {menuItems && menuItems.length > 0 && <NavigationItems menuItems={menuItems} isBlueNav />}
      </USBList>
    </nav>
  )
}

export default TopBlueMenu
