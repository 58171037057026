import { useReducer, useCallback } from 'react'
import axios from 'axios'
import * as actionTypes from './ActionTypes'
import {
    getErrorResponse,
} from '../utils/Utility'
import keepAliveAxios from '../utils/KeepAliveAxios'
/**
 * initial state object used by useReducer
 */
const initialState = {
    loading: false,
    loaded: false,
    error: null,
}

/**
 * Function being used by useReducer
 * @param {object} currState - current state object
 * @param {object} action - action with type and payload
 */
const httpReducer = (currState, action) => {
    switch (action.type) {

        case actionTypes.KEEPALIVE_REQUEST_SENT:
            return {
                loading: true,
                loaded: false,
                error: null
            }

        case actionTypes.KEEPALIVE_RECVD:
            return {
                ...currState,
                loading: false,
                loaded: true,
                error: null,
            }

        case actionTypes.CLEAR_STATE:
            return initialState
        case actionTypes.KEEPALIVE_ERROR:
            return { loading: false, loaded: false, error: action.errorResponse }
        default:
            return new Error(`Action type ${action.type} not handled.`)
    }
}

/**
 * Custom hook for handling user authentication and authorization
 */
const useKeepAliveHook = () => {
    const [httpState, dispatch] = useReducer(httpReducer, initialState)

    /**
     * Clears the state
     */
    const clear = useCallback(() => {
        dispatch({ type: actionTypes.CLEAR_STATE })
    }, [])

    /**
    * keep user's ForgeRock session Alive
    */
    const keepalive = useCallback(async () => {
        let source = {}
        const { CancelToken } = axios
        source = CancelToken.source()

        dispatch({ type: actionTypes.KEEPALIVE_REQUEST_SENT })

        await keepAliveAxios
            .keepAlive()
            .then(res => {

                dispatch({
                    type: actionTypes.KEEPALIVE_RECVD,
                    responseData: res.data
                })
            })
            .catch(err => {
                dispatch({ type: actionTypes.KEEPALIVE_ERROR, errorResponse: getErrorResponse(err,'KeepAlive') })
            })

        return () => {
            if (source) source.cancel()
        }
    }, [])

    /**
     * Returns props, functions and methods to use for derived page/component
     * Sample Usage: const { isAuthenticating, userInfo, error, authenticateUser, clearUserInfo } = useKeepAliveHook()
     */
    return {
        ...httpState,
        clear,
        keepalive
    }
}

export default useKeepAliveHook
