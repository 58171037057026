import React from 'react'
import PropTypes from 'prop-types'
import { withApollo } from '../utils/apollo'

// import App from 'next/app'

// Globally importing CSS on every page.
/* TODO: this should be refactored once SHIELD */
// provides component-level CSS and individual
// component imports.
// For different "themes", import different design-tokens
// See: https://nextjs.org/blog/next-9-2#built-in-css-support-for-global-stylesheets

// import '@usb-shield/design-tokens/dist/responsive-web/consumer/consumer.custom-properties.css'
import '@usb-shield/design-tokens/dist/responsive-web/aem/eas/eas.custom-properties.css'
// import '@usb-shield/components-react/dist/styles/shield.css'
// import '@usb-shield/react-button/dist/library/styles/index.css'
import 'primereact/resources/themes/nova/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'
import '@usb-shield/themes/dist/styles/shield.css'
import '@usb-shield/react-button/dist/library/styles/index.css'
import '@usb-shield/react-grid/dist/library/styles/index.css'
import '@usb-shield/react-icons/dist/library/styles/index.css'
import '@usb-shield/react-input/dist/library/styles/index.css'
import '@usb-shield/react-link/dist/library/styles/index.css'
import '@usb-shield/react-list/dist/library/styles/index.css'
import '@usb-shield/react-loader/dist/library/styles/index.css'
import '@usb-shield/react-modal/dist/library/styles/index.css'
import '@usb-shield/react-notification/dist/library/styles/index.css'
import '@usb-shield/react-select/dist/library/styles/index.css'
import '@usb-shield/react-tooltip/dist/library/styles/index.css'
import '@usb-shield/react-checkbox/dist/library/styles/index.css'
import '@usb-shield/react-radio/dist/library/styles/index.css'
import '@usb-shield/react-table/dist/library/styles/index.css'
import '../styles/Common.css'
import '../styles/Login.css'
import '../styles/Index.css'
import '../styles/TopMenu.css'
import '../styles/Footer.css'
import '../styles/Layout.css'
import '../styles/UserManager.css'
import '../styles/UserRoles.css'
import '../styles/PrimePassword.css'
import '../styles/CustomCheckBox.css'
import '../styles/atmProjects.css'
import '../styles/responsivePlayer.css'
import '../styles/tm.css'
import Layout from '../components/hoc/LayOut/LayOut'
import AuthContextProvider from '../context/AuthContext'
import ErrorBoundary from '../utils/ErrorBoundary'
import SessionIdleTimer from '../components/hoc/SessionIdleTimer/SessionIdleTimer'
// uncomment below if using getInitialProps
// import App from 'next/app'

function MyApp({ Component, pageProps }) {
  //
  // All Global Wrappers & Providers Go Here, for example:
  // state management, CSS themes, tracker provider, apollo
  //

  return (
    <AuthContextProvider>
      <SessionIdleTimer>
        {typeof window !== 'undefined' && (
        <Layout>
          <ErrorBoundary>
            <Component
              {...pageProps} // eslint-disable-line react/jsx-props-no-spreading
            />
          </ErrorBoundary>
        </Layout>
        )}
      </SessionIdleTimer>
    </AuthContextProvider>
  )
}

// Only uncommet this method if you have blocking data requirements for
// every single page in your application. This disables the ability to
// perform automatic static optimization, causing every page in your app to
// be server-side rendered.
//
// MyApp.getInitialProps = async (appContext) => {
//   // calls page's `getInitialProps` and fills `appProps.pageProps`
//   const appProps = await App.getInitialProps(appContext)
//   return { ...appProps }
// }

MyApp.propTypes = {
  Component: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  pageProps: PropTypes.object
}

MyApp.defaultProps = {
  pageProps: undefined
}

export default withApollo(MyApp)
