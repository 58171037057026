/* eslint-disable no-unused-vars */
import React from 'react'
import {
  USBModal,
  USBModalFooter,
  USBModalHeader,
  USBModalBody,
  USBModalCloseIcon
} from '@usb-shield/react-modal'
import USBButton from '@usb-shield/react-button'

import { PropTypes } from 'prop-types'
import {useEffect} from 'react'

/**
 * Returns USBModal popup
 * @param {string} modalTitle - Title to display on popup
 * @param {string} cancelButtonText - text to display on cancel button
 * @param {string} continueButtonText - text to display on continue button
 * @param {function} cancelClick -  event handler for canceling the user action
 * @param {function} continueClick - event handler for continuing the user action
 * @param {string} labelledBy - labelledBy for aria accessibility
 * @param {children} children - content to show
 *
 */
const WarningPopup = ({
  modalTitle,
  cancelButtonText,
  continueButtonText,
  cancelClick,
  continueClick,
  labelledBy,
  children
}) => {
  useEffect(()=>{
    let i = null
    if (modalTitle === 'Session Timeout Warning')
       i = 0
    return () => {
      // eslint-disable-next-line no-shadow
      const i = 0
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  return (
    <>
      <USBModal isOpen>
        {/* <USBModalCloseIcon
          modalTitle="Close"
          handleModalClose={cancelClick}
          ariaLabel="cancel warning popup"
        /> */}

        <USBModalHeader
          id="test-modal-header"
          labelledBy={labelledBy}
          tag="h1"
          modalTitle={modalTitle}
        />

        <USBModalBody>{children}</USBModalBody>

        <USBModalFooter>
          <div style={{ width: '100%', textAlignLast: 'right' }}>
            <USBButton
              type="button"
              variant="secondary"
              size="small"
              ariaLabel="Cancel"
              id="btnWarnPopupCancel"
              name="btnWarnPopupCancel"
              datatestid="btnWarnPopupCancel"
              handleClick={cancelClick}
            >
              {cancelButtonText}
            </USBButton>
            <USBButton
              type="button"
              variant="primary"
              size="small"
              ariaLabel="Continue"
              id="btnWarnPopupContinue"
              name="btnWarnPopupContinue"
              datatestid="btnWarnPopupContinue"
              handleClick={continueClick}
            >
              {continueButtonText}
            </USBButton>
          </div>
        </USBModalFooter>
      </USBModal>
    </>
  )
}

WarningPopup.propTypes = {
  modalTitle: PropTypes.string,
  cancelButtonText: PropTypes.string,
  continueButtonText: PropTypes.string,
  labelledBy: PropTypes.string.isRequired,
  cancelClick: PropTypes.func.isRequired,
  continueClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
}

WarningPopup.defaultProps = {
  modalTitle: '',
  cancelButtonText: 'Cancel',
  continueButtonText: 'Continue'
}

export default WarningPopup
