import React from 'react'
import USBLoader from '@usb-shield/react-loader'

/**
 * NOT Using. using usb shield Loader component.
 */
const PageLoading = () => {
  return (
    // <div className="page-loading">
    //   <div />
    // </div>

    <div className="usb-page-loading">
      <div style={{ height: '100%', width: '100%' }}>
        <USBLoader determinate={false} variant="complex" />
      </div>
    </div>
  )
}

export default PageLoading
