import React, {useContext, useState, useEffect} from 'react'
import USBList from '@usb-shield/react-list'
import USBTooltip from '@usb-shield/react-tooltip'
import {USBIconInfo} from '@usb-shield/react-icons'
import NavigationItems from '../NavigationItems/NavigationItems'
import {AuthContext} from '../../../context/AuthContext'
import {userType, getFriendlyRoleNames, isLoginEnabled, externalApplicationUrls, userGuideUrls, applicationIds, isTrakkerNavigationEnabled} from '../../../utils/Utility'

/**
 * Return top white menu with next links (routable)
 * Renders only if user is authenticated
 */
const TopWhiteMenu = () => {
  const authContext = useContext(AuthContext)
  const [menuItems, setMenuItems] = useState([
    {
      href: '/Logout',
      alt: 'Log Out',
      text: 'Log Out',
      id: 'lnkLogOut'
    }
  ])

  /**
   * Handles page protection
   */
  useEffect(() => {
    const arr = [...menuItems]

    // if (isLoginEnabled()){

    //   if(authContext.applicationId.toLowerCase() === applicationIds.Trakker.toLowerCase()){
    //     arr.unshift({
    //       href: userGuideUrls.trakker,
    //       alt: 'Help',
    //       text: 'Help',
    //       id: 'lnkTrkHelp',
    //       target: '_blank'
    //     })
    //   }
    //   else if(authContext.applicationId.toLowerCase() === applicationIds.TrakkerFI.toLowerCase()){
    //     arr.unshift({
    //       href: userGuideUrls.trakkerFI,
    //       alt: 'Help',
    //       text: 'Help',
    //       id: 'lnkTrkHelp',
    //       target: '_blank'
    //     })
    //   }
    //   else if(authContext.applicationId.toLowerCase() === applicationIds.ATMAccessManagement.toLowerCase()){
    //     arr.unshift({
    //       href: userGuideUrls.atmAccessMgmt,
    //       alt: 'Help',
    //       text: 'Help',
    //       id: 'lnkTrkHelp',
    //       target: '_blank'
    //     })
    //   }
    // }

    if(
      authContext &&
      authContext.userRoles &&
      authContext.userRoles.indexOf(userType.TrakkerUser) > -1
    ) {
      if(externalApplicationUrls.trakkerNew)
        arr.unshift({
          href: externalApplicationUrls.trakkerNew,
          alt: 'New Trakker Website',
          text: 'NEW Trakker',
          id: 'lnkTrakkerNew'
        })
      if(externalApplicationUrls.trakker)
        arr.unshift({
          href: externalApplicationUrls.trakker,
          alt: 'Trakker Website',
          text: 'Trakker',
          id: 'lnkTrakker'
        })
    }


    setMenuItems(arr)
  }, [authContext && authContext.userRoles, authContext && authContext.companyName])

  const getCompanyDetails = () => {
    const arr = []

    if(isLoginEnabled()) arr.push(authContext.companyId)
    arr.push(authContext.companyName)
    return arr.join(', ')
  }

  const userLabel = (
    <USBList.Item key="li_username">
      <div className="welcome" data-testid="welcomediv">
        <div className="username">{`Hi, ${authContext.firstName} `}</div>
        <div className="info">
          <USBTooltip
            variant="light"
            ariaLabel="User Info"
            direction="bottom"
            toolTipIcon={() => <USBIconInfo toolTipVisible size={16} title="User Info" />}
          >
            {authContext.userId && authContext.userId.toLocaleUpperCase()}
            <br />
            {`${authContext.firstName} ${authContext.lastName}`}
            <br />
            {getCompanyDetails()}
            <br />
            {getFriendlyRoleNames(authContext.userRoles).join(', ')}
            <br />
          </USBTooltip>
        </div>
      </div>
    </USBList.Item>
  )

  return (
    <nav id="topWhiteNav">
      <USBList variant="horizontal" type="ul" role="menu">
        {userLabel}
        {menuItems && menuItems.length > 0 && <NavigationItems menuItems={menuItems} />}
      </USBList>
    </nav>
  )
}

export default TopWhiteMenu
