/* eslint-disable no-underscore-dangle */
import React, { useContext, useEffect, useState, useRef } from 'react'
import { PropTypes } from 'prop-types'
import { USBGrid, USBColumn } from '@usb-shield/react-grid'
import { useRouter } from 'next/router'
import TopWhiteMenu from '../../molecules/TopWhiteMenu/TopWhiteMenu'
import TopBlueMenu from '../../molecules/TopBlueMenu/TopBlueMenu'
import Footer from '../../molecules/Footer/Footer'
import Home from '../../../pages/index'

import {
  getPageTitle,
  isNullOrEmpty,
  apiURLs,
  getBackgroundImageURL,
  isLoginEnabled,
  atmConnectVersion
} from '../../../utils/Utility'
import { AuthContext } from '../../../context/AuthContext'
import useLoginHook from '../../../hooks/LoginHook'
import PageLoading from '../../atoms/PageLoading/PageLoading'
import Content from './Content';
import useFeaturesHook from "../../../hooks/FeaturesHook";


/**
 * Wraps given component with top blue & white menus and footer.
 * @param {node} children - component to render
 */
const Layout = ({ children }) => {
  const { userInfo, userInfoError, logLogin, isAuthenticating: isLoading } = useLoginHook()
  const [isForgeRockAuthenticating, setForgeRockAuthenticating] = useState(false)

  const {
    isLoadingFeatures,
    features,
    errorLoadingFeatures,
    getFeatures,
    clearFeatures
  } = useFeaturesHook('')

  const router = useRouter()
  const authContext = useContext(AuthContext)
  const refDivMain = useRef()
  const isAuthenticated = () => {
    const isAuth = authContext && authContext.isAuthenticated
    return isAuth
  }

  const getPageHeading = () => {
    let heading = 'Login'
    if (isAuthenticated()) heading = router.route ? getPageTitle(router.route) : 'Login'
    // if internal user we don't show login page
    if (!isLoginEnabled() && (heading === 'Login' || heading === 'Home')) heading = ''
    return heading
  }

  const [backgroundimage, setBackgroundImage] = useState('')

  const isFooterLink = () => {
    const footerLinks = ['Help', 'Contact Us', 'Privacy & Security']
    return footerLinks.find(o => o === getPageTitle(router.route))
  }

  useEffect(() => {
    if (window.document) {
      const heading = getPageHeading()
      let str = ''
      if (heading === 'Login') str = getBackgroundImageURL('LoginPage1.png')
      else if (heading === '') str = getBackgroundImageURL('landingPage.png')
      // for footer links, we should not display background image.
      if (isFooterLink()) str = ''
      // for external users who are not logged in and click on usbank logo
      if (isLoginEnabled() && !isAuthenticated() && router.route === '/') str = ''

      setBackgroundImage(str)
    }
  }, [typeof window !== 'undefined', router && router.route, authContext.isAuthenticated])

  /**
   * Triggers first time when browser loads the site
   */
  useEffect(() => {
    // UserProfile needs to execute authorization itself and that is why
    // logLogin is suppressed in Layout for UserProfile
    if (!authContext.isAuthenticated && !router.route.startsWith('/UserProfile'))
    logLogin({ companyId: '000000', userId: '000000' })
  }, [])

  /**
   * After calling loglogin, we'll get userInfo, and if userinfo is not null, we update authContext
   */
  useEffect(() => {
    if (userInfo && isNullOrEmpty(userInfo.firstName) !== '') {
      getFeatures();
    }
  }, [userInfo])

  useEffect(() => {
    if (userInfo && features ) {

      authContext.login({ ...userInfo,features, isAuthenticated: true }, true)
    }
  }, [features,userInfo])

  /**
   * Triggers if getUser fails
   * After calling loglogin, we'll get userInfo, and if userinfo is null, we check for error
   */
  useEffect(() => {
    if (userInfoError) {
      if (isLoginEnabled()) authContext.logout({ isUserTimedOut: false, isUserLoggedOut: false })
      else if (!authContext.isAuthenticated) {
        // internal user login
        setForgeRockAuthenticating(true)
        const url = `${apiURLs.forgeRockAuthenticate}&goto=${apiURLs.baseUrl}`
        window.location.href = url
      }
    }
  }, [userInfoError])



  const getChildren = () => {
    // when internal user is not authenticated, always show home page
    // when user is authenticated and children is login, then show home page, so we can avoid the glitch of
    // showing login page for a second and then showing the home page
    if (
      (!isAuthenticated() && !isLoginEnabled()) ||
      (isAuthenticated() && children && children.type.name === 'Login')
    )
      return <Home />

    return children
  }

  return (
    <div id="divMainLayout" ref={refDivMain}>
      {(isLoading || isForgeRockAuthenticating) && <PageLoading />}
      {/* White Menu  */}
      <USBGrid addClasses="white-frame top-menu">
        {/* <USBColumn largeSpan={2} /> */}
        <USBColumn largeSpan={16} addClasses="middle">
          {isAuthenticated() && <TopWhiteMenu />}
        </USBColumn>
        {/* <USBColumn largeSpan={2} /> */}
      </USBGrid>

      {/* Blue Menu  */}
      <USBGrid addClasses="blue-frame top-menu" backgroundColor="usb-blue">
        <USBColumn largeSpan={2} />
        <USBColumn largeSpan={12} addClasses="fit">
          <TopBlueMenu />
        </USBColumn>
        <USBColumn largeSpan={2} />
      </USBGrid>
      <main
        className="backgroundImage"
        style={{
          backgroundImage: backgroundimage
        }}
      >
        {getChildren()}
        
      </main>
      
      {/* Footer */}
      <footer>
        <USBGrid addClasses="blue-frame footer" backgroundColor="usb-blue">
          {/* <USBColumn largeSpan={2} /> */}
          <USBColumn largeSpan={16} addClasses="middle">
            <Footer />
          </USBColumn>
          {/* <USBColumn largeSpan={2} /> */}
        </USBGrid>
        <USBGrid backgroundColor="usb-blue">
          <USBColumn largeSpan={16} addClasses="journeyLine">
            <img src="/svg/journeyline.svg" alt="U.S. Bank" className="journeyLineImage" />
          </USBColumn>
          <USBColumn largeSpan={16}>

            <div className='appversion'>{`ver. ${process.env.REACT_APP_VERSION || process.env.NEXT_PUBLIC_VERSION }`}</div>
          </USBColumn>

        </USBGrid>
      </footer>

    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node
}

Layout.defaultProps = {
  children: null
}
export default Layout
