import React from 'react'
import { PropTypes } from 'prop-types'
import NavigationItem from '../../atoms/NavigationItem/NavigationItem'

/**
 * Creates next links (Routable)
 * @param {arrayOf(object)} menuItems - array of menuItems contains props to generate links.
 * @param {boolean} isBlueNav - adds css classes for blue/white background navigation.
 */
const NavigationItems = ({ menuItems, isBlueNav }) => {
  return (
    menuItems &&
    menuItems.length > 0 &&
    menuItems.map(menuItem => {
      return (
        menuItem && <NavigationItem key={menuItem.id} menuItem={menuItem} isBlueNav={isBlueNav} />
      )
    })
  )
}

NavigationItems.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired,
      target: PropTypes.string,
      handleClick: PropTypes.func
    })
  ).isRequired,
  isBlueNav: PropTypes.bool
}

NavigationItems.defaultProps = {
  isBlueNav: false
}

export default NavigationItems
