import React, {useContext, useState, useEffect, useCallback, } from 'react'
import {PropTypes} from 'prop-types'
import {useIdleTimer} from 'react-idle-timer'
import {isArray, now} from 'lodash'

import {AuthContext} from '../../../context/AuthContext'
import WarningPopup from '../../atoms/WarningPopup/WarningPopup'

import useKeepAliveHook from '../../../hooks/KeepAliveHook'
import {formatInterval, formatTime, isLoginEnabled} from '../../../utils/Utility'
import userManagerAxios from '../../../utils/UserManagerAxios'
import companyManagerAxios from '../../../utils/CompanyManagerAxios'
import keepAliveAxios from '../../../utils/KeepAliveAxios'
import useLoginHook from '../../../hooks/LoginHook'

/**
 * Wraps given component with session timer feature enabled.
 * @param {node} children - component to render
 */
const SessionIdleTimer = ({children}) => {


  const authContext = useContext(AuthContext)
  const {logoutUser} = useLoginHook()
  const {updateState, isAuthenticated, logout, toggleShowTimings, showTimings} = authContext
  const [showModal, setShowModal] = useState(false)
  const [lastKeepAliveTimeMs, setLastKeepAliveTimeMs] = useState(now)

  const keepAliveIntervalMinutes = 1
  const [inputTimeoutMinutes, setInputTimeoutMinutes] = useState(12.5 * keepAliveIntervalMinutes)
  const [warningTimeoutMinutes, setWarningTimeoutMinutes] = useState(2 * keepAliveIntervalMinutes)
  const oneMinuteMs = 1000 * 60 * keepAliveIntervalMinutes
  const keepAliveIntervalMs = oneMinuteMs * keepAliveIntervalMinutes
  const inputTimeOutMs = oneMinuteMs * inputTimeoutMinutes
  const intervalMs = 1000
  const warningTimeoutMs = Math.round(oneMinuteMs * warningTimeoutMinutes)
  const [lastActionTimeMs, setLastActionTimeMs] = useState(now)
  const {keepalive, loading} = useKeepAliveHook()
  const [currentTimeMs, setCurrentTimeMs] = useState(now)
  const [timerActions, setTimerActions] = useState([])

  const warningMessage = useCallback(() => {
    return (
      <>
        <div>{`You will be logged out in approximately ${warningTimeoutMinutes} minutes.`}</div>
        <div>Would you like to extend your session?</div>
      </>
    )
  }, [warningTimeoutMinutes])

  const handleLogout = () => {
    setShowModal(false)
    logoutUser()
    if (!isLoginEnabled() && typeof window !== 'undefined') {
      logout({
        isUserLoggedOut: false,
        isUserTimedOut: true,
        redirectToLoginPage: false
      })
    }
    else
      logout({isUserLoggedOut: false, isUserTimedOut: true})
  }

  const onIdle = () => {
    handleLogout()
  }
  const callKeepAlive = useCallback(() => {
    keepalive()
  }, [keepalive])

  const onActive = () => {
    setShowModal(false)
    callKeepAlive()
  }

  const onAction = (ev) => {
    setLastActionTimeMs(now())

    if (timerActions && isArray(timerActions))
      setTimerActions((prevActions) => {
        prevActions.push(ev)
        return prevActions
      }
      )
  }
  const onPrompt = () => {
    setShowModal(true)
  }
  const {
    start,
    reset,
    activate,
    pause,
    // resume,
    // isIdle,
    isPrompted,
    // isLeader,
    // getTabId,
    getRemainingTime,
    getElapsedTime,
    getLastIdleTime,
    getLastActiveTime,
    getTotalIdleTime,
    getTotalActiveTime
  } = useIdleTimer({
    /** ******************** */
    // idle detection=id, Activity Detection=ad, Confirm Prompt=cp
    // [id, cp] timeout: The time before a user is considered idle.
    timeout: inputTimeOutMs,
    // [id, ad] element: The DOM element to bind event listeners to.
    // element: document,
    // [id, ad] events: The events to listen for activity on.
    events: [
      'change',
      'contextmenu',
      'copy',
      'cut',
      'dblclick',
      'input',
      'keyup',
      'paste',
      'select',
      'submit',
      'load',
      'click'
    ],
    // [id] immediateEvents: The events that will immediately trigger an idle event (bypassing timeout).
    immediateEvents: [],
    // [id] onIdle: Function called when a user becomes idle from an active state.
    onIdle,
    // [id] onActive: Function called when a user becomes active from an idle state.
    onActive,
    // [id] eventsThrottle: Throttle for the events bound. Saves on CPU if you are using repeated events.
    eventsThrottle: 0,
    // [id, ad] startOnMount: Starts the timer when the host component mounts.
    startOnMount: false,
    // [id, ad] startManually: Require a call to start() in order to start the timer.
    startManually: true,
    // [id] stopOnIdle: Require the timer to be manually reset after going idle by calling start() or reset()
    stopOnIdle: false,
    // onPrompt: Function called after the idle timeout is reached.
    onPrompt,
    // [ad] onAction: Function called each time an event is triggered.
    onAction,
    // [cp] promptTimeout: How long the prompt should last before going idle in milliseconds.
    promptTimeout: warningTimeoutMs,
    // [ad] debounce: Debounce the onAction callback in milliseconds.
    debounce: 200,
    // [ad] throttle: Throttle the onAction callback in milliseconds.    
    throttle: 0,
    crossTab: false,
    name: 'idle-timer',
    syncTimers: 0,
    leaderElection: false,
  })

  useEffect(() => {
    const responseInterceptor = (response) => {
      setLastActionTimeMs(now())
      reset()
      start()
      activate()
      return response
    };

    userManagerAxios.setResponseInterceptor(responseInterceptor);
    companyManagerAxios.setResponseInterceptor(responseInterceptor);
    keepAliveAxios.setResponseInterceptor(responseInterceptor);

    return () => {
      userManagerAxios.responseInterceptor = null
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);

  useEffect(() => {
    if (isAuthenticated) {
      activate()
    }
    else {
      reset()
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isAuthenticated])

  const handleKeepAlive = () => {
    setShowModal(false)
    activate()
  }
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTimeMs(now)
      const remainingSessionTimeMs = getRemainingTime()
      updateState({sessionTimeLeft: remainingSessionTimeMs})
      if (!isPrompted())
        if ((currentTimeMs - lastKeepAliveTimeMs) >= keepAliveIntervalMs && (lastActionTimeMs - lastKeepAliveTimeMs) > 0 && !loading) {
          setLastKeepAliveTimeMs(0)
          reset()
          callKeepAlive()
        }

    }, intervalMs)
    return () => {
      clearInterval(interval)
    }
  }, [callKeepAlive, getLastActiveTime, getRemainingTime, isPrompted, lastActionTimeMs, lastKeepAliveTimeMs, currentTimeMs, loading, reset, keepAliveIntervalMs, intervalMs, updateState])

  useEffect(() => {
    if (!loading) {
      const nowMs = now
      setLastKeepAliveTimeMs(nowMs)
      setLastActionTimeMs(nowMs)
    }
  }, [inputTimeOutMs, loading])

  const handleOnDoubleClick = useCallback(() => {
    toggleShowTimings()
  }, [toggleShowTimings])

  const handleKeyDown = useCallback(() => {}, [])

  const getDebugData = useCallback(() => {


    const remainingTimeMs = getRemainingTime()
    const style1 = {width: 250, textAlign: 'right', fontFamily: 'monospace', margin: 3, padding: 3, fontWeight: 600}
    const style2 = {width: 250, textAlign: 'left', fontFamily: 'monospace', margin: 3, padding: 3}
    const emphasize = {width: 250, textAlign: 'left', fontFamily: 'monospace', margin: 3, padding: 3, color: 'DarkGreen', fontWeight: 800}
    const changing = {width: 250, textAlign: 'left', fontFamily: 'monospace', margin: 3, padding: 3, color: 'DarkGreen', fontWeight: 800}
    return (


      <span
        role='button'
        tabIndex={0}
        onDoubleClick={handleOnDoubleClick}
        onKeyDown={handleKeyDown}
      >
        <table className={`timings${(showTimings ? 'show' : 'hide')}`}>

          <tbody>
            <tr>
              <td style={style1}>name</td>
              <td style={style2}>value</td>
              <td rowSpan={20}>

                <ul>
                  {timerActions && (timerActions.length > 0) && timerActions.map(
                    (a) => {
                      return (
                        <li style={{border: '2px solid var(--token-color-outline)'}}>
                          <p>
                            <span style={{fontWeight: 800}}>event type:&nbsp;</span>
                            {`'${a.type}'`}
                          </p>
                        </li>
                      )
                    })}
                </ul>
              </td>
            </tr>
            <tr>
              <td style={style1}>Current Time</td>
              <td style={changing}>{`${formatTime(currentTimeMs)}`}</td>
            </tr>
            <tr>
              <td style={style1}>***** Constants *****************</td>
              <td style={style2}>*********************************</td>
            </tr>
            <tr>
              <td style={style1}>Idle time before warning</td>
              <td style={style2}>
                <input
                  value={`${inputTimeoutMinutes}`}
                  onChange={(e) => {
                    if (!e.target.value || e.target.value < keepAliveIntervalMinutes)
                      setInputTimeoutMinutes(keepAliveIntervalMinutes)
                    else if (e.target.value > 12.75)
                      setInputTimeoutMinutes(12.75)
                    else
                      setInputTimeoutMinutes(e.target.value)
                  }}
                />
              </td>
            </tr>
            <tr>
              <td style={style1}>Warning Timeout </td>
              <td style={style2}>
                <input
                  value={`${warningTimeoutMinutes}`}
                  onChange={(e) => {
                    if (!e.target.value || e.target.value < keepAliveIntervalMinutes)
                      setWarningTimeoutMinutes(keepAliveIntervalMinutes)
                    else if (e.target.value > inputTimeoutMinutes || e.target.value > 10)
                      setWarningTimeoutMinutes(Math.min(inputTimeoutMinutes, 10))
                    else
                      setWarningTimeoutMinutes(e.target.value)
                  }}
                />
              </td>
            </tr>
            <tr>
              <td style={style1}>time between keepalive calls </td>
              <td style={style2}>{`00:${formatInterval(keepAliveIntervalMs)}`}</td>
            </tr>
            <tr>
              <td style={style1}>***** Warning *****************</td>
              <td style={style2}>***** Popup   *****************</td>
            </tr>
            <tr>
              <td style={style1}>Remaining Time (mm:ss)</td>
              <td style={style2}>{remainingTimeMs ? `00:${formatInterval(remainingTimeMs)}` : ''}</td>
            </tr>
            <tr>
              <td style={style1}>Next Warning Popup</td>
              <td style={emphasize}>{`${formatTime(currentTimeMs + remainingTimeMs - warningTimeoutMs)}`}</td>
            </tr>
            <tr>
              <td style={style1}>***** Keep *****************</td>
              <td style={style2}>***** Alive ****************</td>
            </tr>
            <tr>
              <td style={style1}>Last Keep Alive Time (ms) </td>
              <td style={style2}>{`${formatTime(lastKeepAliveTimeMs)}`}</td>
            </tr>
            <tr>
              <td style={style1}>Next KeepAlive Time </td>
              <td style={style2}>{`${formatTime(new Date(lastKeepAliveTimeMs + keepAliveIntervalMs))}`}</td>
            </tr>
            <tr>
              <td style={style1}>Last Action Time </td>
              <td style={emphasize}>{`${formatTime(lastActionTimeMs)}`}</td>
            </tr>
            <tr>
              <td style={style1}>Will call next keepalive</td>
              <td style={style2}>{`${(lastActionTimeMs - lastKeepAliveTimeMs) > 0 ? 'yes: Last Action occurred after Last Keep Alive' : 'no: Last Action never occurred or occ=ured before Last Keep Alive'}`}</td>
            </tr>
            <tr>
              <td style={style1}>time to next keep alive</td>
              <td style={emphasize}>{lastKeepAliveTimeMs && lastActionTimeMs && (lastActionTimeMs - lastKeepAliveTimeMs > 0) ? `00:${formatInterval(lastKeepAliveTimeMs + keepAliveIntervalMs - currentTimeMs)}` : null}</td>
            </tr>
            <tr>
              <td style={style1}>Elapsed Time </td>
              <td style={style2}>{`00:${formatInterval(getElapsedTime())}`}</td>
            </tr>
            <tr>
              <td style={style1}>Last Idle Time </td>
              <td style={style2}>{getLastIdleTime() ? `${formatTime(getLastIdleTime())}` : ''}</td>
            </tr>
            <tr>
              <td style={style1}>Last Active Time </td>
              <td style={style2}>{`${formatTime(getLastActiveTime())}`}</td>
            </tr>
            <tr>
              <td style={style1}>Total Idle Time </td>
              <td style={style2}>{`00:${formatInterval(getTotalIdleTime())}`}</td>
            </tr>
            <tr>
              <td style={style1}>Total Active Time </td>
              <td style={style2}>{`00:${formatInterval(getTotalActiveTime())}`}</td>
            </tr>

            <tr>
              <td style={style1}>Interval (ms)</td>
              <td style={style2}>{`${intervalMs}`}</td>
            </tr>
          </tbody>
        </table>
      </span>
    )

  }, [getRemainingTime, handleOnDoubleClick, handleKeyDown, showTimings, timerActions, currentTimeMs, inputTimeoutMinutes, warningTimeoutMinutes, keepAliveIntervalMs, warningTimeoutMs, lastKeepAliveTimeMs, lastActionTimeMs, getElapsedTime, getLastIdleTime, getLastActiveTime, getTotalIdleTime, getTotalActiveTime])

  return (
    <>
      {authContext && isAuthenticated && (
        <>
          {showModal && (
            <WarningPopup
              modalTitle="Session Timeout Warning"
              labelledBy="Warning"
              cancelClick={handleKeepAlive}
              continueClick={handleLogout}
              cancelButtonText="Extend"
              continueButtonText="Logout"
            >
              {warningMessage()}
            </WarningPopup>
          )}
          {getDebugData()}

        </>
      )}
      {children}
    </>
  )

}

SessionIdleTimer.propTypes = {
  children: PropTypes.node
}

SessionIdleTimer.defaultProps = {
  children: null
}
export default SessionIdleTimer




