import React, { useContext, useEffect } from 'react'
import PageLoading from '../components/atoms/PageLoading/PageLoading'
import Content from '../components/hoc/LayOut/Content'
import { AuthContext } from '../context/AuthContext'
import useLoginHook from '../hooks/LoginHook'

const Home = () => {
  const { isAuthenticating } = useLoginHook()
  const authContext = useContext(AuthContext)
  useEffect(()=>{
    authContext.updateState({ isFormChanged: false })

  },[])
  return (
  
    <Content>
      {isAuthenticating && <PageLoading />}
    </Content>
  )
}

export default Home
