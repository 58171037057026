/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState, useEffect } from 'react'
import USBList from '@usb-shield/react-list'
import USBLink from '@usb-shield/react-link'
import { PropTypes } from 'prop-types'
import Link from 'next/link'
import Router from 'next/router'
import { AuthContext } from '../../../context/AuthContext'
import WarningPopup from '../WarningPopup/WarningPopup'

/**
 * Returns next link (Routable)
 * NOTE: External routes are creating using USBLink, Next link is only recommended for internal routing
 * @param {object} menuItem - menuItem contains props to generate a link.
 * @param {boolean} isBlueNav - adds css classes for blue/white background navigation.
 */
const NavigationItem = ({ menuItem, isBlueNav }) => {
  const authContext = useContext(AuthContext)
  const [menuItemHref, setMenuItemHref] = useState('')
  const [showChangesWarning, setShowChangesWarning] = useState(false)
  const [continueRouting, setContinueRouting] = useState(false)

  /**
   * Event handler for menu item click
   * Checks the global variable isFormChanged and if true resume's the routing
   * and displays confirmation
   * @param {event} e - event
   * @param {string} href - routing address. example: /UserManager
   */
  const onMenuItemClick = (e, href) => {
    // console.log(`onMenuItemClick: ${href}`)
    setMenuItemHref(href)

    if (authContext.isFormChanged) {
      setShowChangesWarning(true)
      e.preventDefault()
    }
  }

  /**
   * Triggers on continueRouting value change
   * if continueRouting is true, continues the routing
   */
  useEffect(() => {
    if (continueRouting && menuItemHref !== '') {
      const href = menuItemHref
      // console.log(`showChangesWarning: ${href}`)
      setMenuItemHref('')
      Router.push(href)
      setContinueRouting(false)
    }
  }, [continueRouting])

  /**
   * Warning notification cancel button click event handler
   */
  const onWarnPopupCancelClick = () => {
    setShowChangesWarning(false)
    setContinueRouting(false)
  }

  /**
   * Warning notification continue button click event handler
   */
  const onWarnPopupContinueClick = () => {
    authContext.updateState({ isFormChanged: false })
    setShowChangesWarning(false)
    setContinueRouting(true)
  }

  return (
    <>
      {showChangesWarning && (
        <WarningPopup
          modalTitle="Warning"
          labelledBy="Warning"
          cancelClick={onWarnPopupCancelClick}
          continueClick={onWarnPopupContinueClick}
        >
          <div>Changes detected, you will lose all the changes you have made.</div>
          <div>Are you sure you want to continue?</div>
        </WarningPopup>
      )}

      <USBList.Item key={`li_${menuItem.id}`} role="menuitem">
        {
          (!menuItem.href) ? (
            <USBLink
              href='#'
              key={menuItem.id}
              addClasses={isBlueNav ? 'bluebar-menuitem' : 'whitebar-menuitem'}
              linkType="subtle"
              handleClick={menuItem.handleClick}
            >
              {menuItem.text}
            </USBLink>
          ) : (
            (/http|https/.test(menuItem.href)) ? (
              <USBLink
                href={menuItem.href}
                key={menuItem.id}
                addClasses={isBlueNav ? 'bluebar-menuitem' : 'whitebar-menuitem'}
                linkType="subtle"
                target={menuItem.target}
              >
                {menuItem.text}
              </USBLink>
            ) : (
              <Link href={menuItem.href} as={menuItem.href} key={menuItem.id}>
                <div
                  onClick={e => {
                  onMenuItemClick(e, menuItem.href)
                }}
                >
                  <a
                    href="#"
                    className={isBlueNav ? 'subtle bluebar-menuitem' : 'subtle whitebar-menuitem'}
                  >
                    {menuItem.text}
                  </a>
                </div>
              </Link>
            )
          )
        }

      </USBList.Item>
    </>
  )
}

NavigationItem.propTypes = {
  menuItem: PropTypes.shape({
    id: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    target: PropTypes.string,
    role: PropTypes.string,
    handleClick: PropTypes.func
  }).isRequired,
  isBlueNav: PropTypes.bool
}

NavigationItem.defaultProps = {
  isBlueNav: false
}

export default NavigationItem
